<template>
  <nav class="dg-ppm__sidebar">
    <div class="dg-ppm__sidebar__container">
      <h3 class="dg-ppm__sidebar__title">{{ $t("ppm.sidebar.title") }}</h3>
      <ul class="dg-ppm__sidebar__list">
        <li>
          <router-link class="dg-ppm__sidebar__item" :to="{ name: 'PPMDashboard' }">
            <div class="dg-ppm__sidebar__item-icon">
              <img src="../images/overview.svg" :alt="$t('ppm.sidebar.overview')" />
            </div>
            <p class="dg-ppm__sidebar__item-name">{{ $t("ppm.sidebar.overview") }}</p>
          </router-link>
        </li>
        <li>
          <router-link class="dg-ppm__sidebar__item" :to="{ name: 'PPMInfoSection' }">
            <div class="dg-ppm__sidebar__item-icon">
              <img src="../images/more-info.svg" :alt="$t('ppm.sidebar.more_info')" />
            </div>
            <p class="dg-ppm__sidebar__item-name">{{ $t("ppm.sidebar.more_info") }}</p>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "PPMSidebar",
  methods: {
    goToView(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm__sidebar {
  min-width: 300px;

  &__container {
    position: fixed;
    top: 65px;
    bottom: 0;
    background-color: #003349;
    color: #fff;
    z-index: 1;
    width: 300px;
  }

  &__title {
    margin-top: 16px;
    padding: 0 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  &__item-name {
    margin: 0;
    font-size: 14px;
    margin-left: 14px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 16px;
  }

  &__item {
    display: flex;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    background-color: inherit;
    border: none;
    color: $white;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
</style>
