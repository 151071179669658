<template>
  <div class="dg-ppm">
    <PPMSidebar />
    <router-view></router-view>
  </div>
</template>

<script>
import PPMSidebar from "../components/PPMSidebar.vue";

export default {
  name: "PPMDashboard",
  components: {
    PPMSidebar,
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm {
  display: flex;
  width: 100%;
  color: #003349;
}
</style>
