<template>
  <dg-link :href="linkTo" type="primary">{{ $t("ppm.list_section.column_labels.details") }}</dg-link>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  computed: {
    linkTo() {
      return {
        name: "PPMDetails",
        params: {
          ppmId: this.data.id,
        },
      };
    },
  },
};
</script>
