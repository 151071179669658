<template>
  <div class="dg-ppm__container">
    <div class="dg-ppm__main">
      <div class="dg-ppm__hero-section">
        <div class="dg-ppm__details">
          <h1 class="dg-ppm__dpa-officer__name">{{ $t("ppm.hero_section.title") }}</h1>
          <p class="dg-ppm__dpa-officer__desc">{{ $t("ppm.hero_section.description") }}</p>
        </div>
        <img class="dg-ppm__dpa-officer__image" :src="`data:image/png;base64, ${mainContactDPOImage}`" />
      </div>
      <PPMCreateSection />
      <PPMTable />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PPMCreateSection from "../components/PPMDashboard/PPMCreateSection.vue";
import PPMTable from "../components/PPMDashboard/PPMTable.vue";

export default {
  name: "PPMDashboard",
  components: {
    PPMCreateSection,
    PPMTable,
  },
  computed: {
    mainContactDPOImage() {
      return this.companyData?.main_contact_dpo?.image;
    },
    ...mapGetters(["companyData"]),
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm {
  &__container {
    flex: 1;
  }

  &__main {
    flex: 1;
    padding: 32px;
    max-width: 1140px;
    margin: 0 auto;
  }

  &__hero-section {
    display: flex;
  }

  &__details {
    flex: 1;
  }

  &__dpa-officer {
    &__name,
    &__desc {
      margin: 0;
      padding: 0;
    }

    &__name {
      font-size: 40px;
      font-weight: 700;
      line-height: 56px;
    }

    &__desc {
      margin-top: 12px;
    }

    &__image {
      width: 360px;
      margin-left: 24px;
    }
  }
}
</style>
