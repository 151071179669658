<template>
  <div class="dg-ppm__create-container">
    <h3 class="dg-ppm__create-container__title">{{ $t(`ppm.create_section.${type}.title`) }}</h3>
    <p class="dg-ppm__create-container__desc">{{ $t(`ppm.create_section.${type}.description`) }}</p>
    <PPMCreateButton :type="type" :data-testid="`ppm-btn-${type}`" />
  </div>
</template>

<script>
import PPMCreateButton from "./PPMCreateButton.vue";

export default {
  name: "PPMCreateContainer",
  components: {
    PPMCreateButton,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm__create-container {
  flex: 1;
  width: 100%;
  max-width: 220px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title,
  &__desc {
    margin: 0;
    padding: 0;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}
</style>
