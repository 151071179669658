<template>
  <div class="dg-ppm__create-section">
    <PPMCreateContainer type="check" v-can:add="'portal_for_dpas_and_jcas_review_workflow'" />
    <PPMCreateContainer type="create" v-can:add="'portal_for_dpas_and_jcas_creation_workflow'" />
    <PPMCreateContainer type="upload" v-can:add="'portal_for_dpas_and_jcas_upload_workflow'" />
  </div>
</template>

<script>
import PPMCreateContainer from "./PPMCreateContainer.vue";

export default {
  components: {
    PPMCreateContainer,
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm {
  &__create-section {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }

  &__create-container {
    flex: 1;
    width: 100%;
    max-width: 220px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title,
    &__desc {
      margin: 0;
      padding: 0;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}
</style>
