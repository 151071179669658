import { render, staticRenderFns } from "./PPMSidebar.vue?vue&type=template&id=73654be2&scoped=true&"
import script from "./PPMSidebar.vue?vue&type=script&lang=js&"
export * from "./PPMSidebar.vue?vue&type=script&lang=js&"
import style0 from "./PPMSidebar.vue?vue&type=style&index=0&id=73654be2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73654be2",
  null
  
)

export default component.exports