<template>
  <div v-if="isPpmActivated" class="dg-ppm__wrapper">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
  <div v-else>
    <UnavailableFeature />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UnavailableFeature from "@/engines/common/components/UnavailableFeature";

export default {
  name: "PPM-Layout",
  components: {
    UnavailableFeature,
  },
  computed: {
    ...mapGetters(["isCustomerFeatureActivated"]),
    isPpmActivated() {
      return this.isCustomerFeatureActivated("portal_for_dpas_and_jcas");
    },
  },
};
</script>

<style lang="scss">
.dg-ppm__wrapper {
  display: flex;
  min-width: 1440px;
}

.dg-ppm__form-container {
  width: 100%;
  max-width: 1040px;
  margin: 24px auto;
  color: #003349;

  &__hero {
    padding: 8px 24px 36px;

    .back-button {
      color: #7e93a7;
      border: none;
    }

    &__title {
      font-size: 40px;
      font-weight: 700;
      line-height: 56px;
    }

    &__desc {
      margin: 0;
      margin-top: 12px;
    }
  }
}
@media only screen and (min-width: 1441px) {
  .dg-ppm__form-container {
    margin: 24px auto;
  }
}
</style>
